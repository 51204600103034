<template>
    <div class="flex-content">
        <div class="section-header flex-alignc-justifyb">
            <div class="flex-sub1 flex-alignc">
                <div class="mr-16">设备</div>
            </div>
        </div>

        <div class="flex-main">
            <div class="main">
                <div class="main-head">
                    <div class="left">
                        <a-icon
                            class="ml-16 pointer"
                            title="导出"
                            type="vertical-align-bottom"
                            @click="exportData"
                            v-if="activeTab != 6"
                        />
                        <a-icon class="ml-16" type="sync" title="刷新" @click="reload"/>
                    </div>
                    <div class="right">
                    </div>
                </div>

                <div class="main-content">
                    <div class="content-right">
                        <div>
                            <a-tabs v-model="activeTab" @change="callback">
                                <a-tab-pane :key="i.value" :tab="i.name" v-for="i in tabList"></a-tab-pane>
                            </a-tabs>
                        </div>
                        <div class="list-box">
                            <div class="weather-box" v-if="activeTab == 6">
                                <div class="one-msg" v-for="item in weatherData">
                                    <span>{{ item.title }}：</span>
                                    <span class="one-detail">{{ item.value }}{{
                                            item.value == "--" ? "" : item.unit
                                        }}</span>
                                </div>
                            </div>
                            <myTable
                                :columns="columns"
                                :dataSource="tableData"
                                :total="total"
                                @changePagination="getTableList"
                                :scroll="{ x: getXWidth, y: yHeight }"
                                v-else
                            >
                                <template #dispersion>
                                    <div>装机容量(KWP)</div>
                                </template>
                                <!--根据安装位置进行查询-->
                                <template slot="childSystem" slot-scope="slotData">
                                    <a @click="getListByParam(slotData.scope.childSystem)">{{
                                            slotData.scope.childSystem
                                        }}</a>
                                </template>
                                <template slot="deviceName" slot-scope="slotData">
                                    <div @click="toDetail(slotData.scope)" class="name-box">
                                        <div>
                                            {{ slotData.scope.deviceTitle ?? "--" }}
                                        </div>
                                        <div v-if="activeTab != 6">
                                            {{
                                                activeTab == 3
                                                    ? slotData.scope.inverterId
                                                    : slotData.scope.deviceSn
                                            }}
                                        </div>
                                    </div>
                                </template>
                                <template #inverStatus="slotData">
                                    <a-tag
                                        :color="getInverStatus(slotData.scope.workStatus).color"
                                    >
                                        {{ getInverStatus(slotData.scope.workStatus).label }}
                                    </a-tag>
                                </template>
                                <template #monitoringStatus="slotData">
                                    {{ getMonitoringStatus(slotData.scope.workStatus) }}
                                </template>

                                <template slot="operation" slot-scope="slotData">
                                    <div class="operation-box">
                                        <a-icon
                                            type="edit"
                                            class="icon-left"
                                            title="编辑"
                                            @click="editOpen(slotData.scope)"
                                        />
                                    </div>
                                </template>
                            </myTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 详情抽屉 -->
        <a-drawer
            placement="right"
            :closable="false"
            :visible="detailDrawer"
            width="83%"
            wrapClassName="add-power"
            @close="closeDetailDrawer"
            :destroyOnClose="true"
            :headerStyle="{
		        background: '#fff',
		        padding: '0.2rem 0.25rem 0 0.25rem',
		        flexGrow: 0,
		      }"
            :bodyStyle="{
		        padding: '0.3rem 0 0.2rem 0',
		        background: 'fff',
		        flexGrow: 1,
		        overflowY: 'auto',
		      }"
            :drawerStyle="{
		        background: '#EEEEEF',
		        display: 'flex',
		        flexDirection: 'column',
		      }"
        >
            <template #title>
                <div class="head-box">
                    <div class="name-box">
                        <span>{{ getTabName }}</span>
                        <div class="operation-right">
                            <a-icon
                                type="deployment-unit"
                                title="查看所属系统"
                                class="mr_16"
                            />
                            <a-icon type="control" title="设备控制" class="mr_16"/>
                            <a-icon type="to-top" title="固件升级" class="mr_16"/>
                            <a-icon
                                type="sync"
                                title="刷新"
                                class="mr_16"
                                @click="getDetail"
                            />
                            <div class="close-box" @click="closeDetailDrawer">
                                <a-icon type="close" class="close-icon"/>
                            </div>
                        </div>
                    </div>
                    <div class="status-box">
                        <div class="left-box">
                            <a-icon
                                type="check-circle"
                                theme="twoTone"
                                two-tone-color="#52c41a"
                            />
                            <span>{{ getStatus }}</span>
                        </div>
                        <span class="right-time">{{
                                moment().format("YYYY-MM-DD HH:mm:ss")
                            }}</span>
                    </div>
                </div>
                <div class="drawer-tab-box">
                    <div class="all-tabs">
			            <span
                            class="drawer-tab"
                            @click="changeDrawerTab(drawertab.value)"
                            v-for="drawertab in drawerTabList"
                            :class="
			                drawertab.value == drawerActive ? 'active-drawer-tab' : ''
			              ">{{ drawertab.label }}</span>
                    </div>
                </div>
            </template>
            <div class="content-box">
                <!-- 逆变器详情-->
                <inverter-detail :sn="currentData.deviceSn" :id="currentData.id" v-if="activeTab==2"></inverter-detail>
            </div>
        </a-drawer>
        <editDrawer
            @closeEditDrawer="closeEditDrawer"
            :currentData="currentData"
            @reloadTable="reloadTable"
            v-if="topDrawer"
            :topDrawer="topDrawer"
        ></editDrawer>
    </div>
</template>

<script>
import {
    stationChildList,
    queryDeviceList,
    queryInverterDetails,
    queryMonitorDetails,
    downloadDevice,
    weatherStation,
} from "@/api/device.js";
import allColumns from "../views/monitoring/config/deviceColumns";
import moment from "moment";
import statusCode from "../views/monitoring/config/statusCode";
import collectDetail from "@/views/monitoring/device-components/collectDetail";
import inverseDetail from "@/views/monitoring/device-components/inverseDetail.vue";
import alarmMsg from "@/views/monitoring/device-components/alarmMsg.vue";
import historyData from "@/views/monitoring/device-components/historyData.vue";
import joinMsg from "@/views/monitoring/device-components/joinMsg.vue";
import monitoringDetail from "../views/monitoring/device-components/monitoringDetail.vue";
import survey from "../views/monitoring/monitoring-components/survey.vue";
import powerThermal from "@/views/monitoring/monitoring-components/powerThermal";
import optimizeDetail from "../views/monitoring/device-components/optimizeDetail.vue";
import ammeterDetail from "../views/monitoring/device-components/ammeterDetail.vue";
import temperature from "@/views/monitoring/monitoring-components/temperature";
import editDrawer from "../views/monitoring/components/editDrawer.vue";
import {exportDown, debound} from "@/utils/index.js";
import InverterDetail from "@/views/monitoring/components/inverterDetail.vue";

export default {
    data() {
        const monitoringStatus = [
            {value: 15, label: "安全保护器已打开"},
            {value: 14, label: "RSVD"},
            {value: 13, label: "RSVD"},
            {value: 12, label: "断线故障"},
            {value: 11, label: "电路故障"},
            {value: 10, label: "高温"},
            {value: 9, label: "命令电弧故障"},
            {value: 8, label: "其他电弧故障"},
            {value: 7, label: "自身电弧故障"},
            {value: 6, label: "漏电故障"},
            {value: 5, label: "断线故障 (组串断线)"},
            {value: 4, label: "PV 低压，PV 电压太低，<12V"},
            {value: 3, label: "命令全部"},
            {value: 2, label: "命令组串"},
            {value: 1, label: "命令单个保护器"},
            {value: 0, label: "启动阶段，仅在安全保护器启动和重新启动阶段"},
        ];
        const inverseStatus = [
            {value: 0, label: "正常", color: "blue"},
            {value: 8000, label: "停机", color: "orange"},
            {value: 1300, label: "按键关机", color: "orange"},
            {value: 1500, label: "紧急停机", color: "orange"},
            {value: 1400, label: "待机", color: "orange"},
            {value: 1200, label: "初始待机", color: "orange"},
            {value: 1600, label: "启动中", color: "orange"},
            {value: 9100, label: "告警运行", color: "orange"},
            {value: 8100, label: "降额运行", color: "orange"},
            {value: 8200, label: "调度运行", color: "orange"},
            {value: 5500, label: "故障停机", color: "orange"},
            {value: 2500, label: "通讯故障", color: "orange"},
            {value: 9999, label: "离线", color: "red"},
        ];
        return {
            mode: "2", // 1 精简模式 2 分析模式
            tableData: [],
            monitoringStatus,
            inverseStatus,
            total: 0,
            flag: 0,
            pagination: {
                page: 1,
                pageSize: 50,
            },
            moment,
            columns: [],
            selectedRowKeys: [],
            rootSubmenuKeys: ["sub1"],
            tabList: [
                {
                    value: "1",
                    name: "采集器",
                },
                {
                    value: "2",
                    name: "逆变器",
                },
            ],
            activeTab: "1",
            systemList: [],
            treeHead: true,
            activeId: this.$route.query.id,
            getTabName: "",
            currentData: {},
            detailDrawer: false,
            //抽屉的tab
            drawerTabList: [
                {
                    value: "0",
                    label: "连接信息",
                },
            ],

            // 常用tab
            normalTabList: [
                {
                    value: "0",
                    label: "详情",
                },
                {
                    value: "1",
                    label: "报警信息",
                },
                {
                    value: "2",
                    label: "连接信息",
                },
                {
                    value: "3",
                    label: "历史数据",
                },
            ],
            // 组件的tab页
            monitoringTab: [
                {
                    value: "0",
                    label: "详情",
                },
                // {
                //     value: "1",
                //     label: "发电量热力图",
                // },
                // {
                //     value: "2",
                //     label: "温度热力图",
                // },
            ],
            drawerActive: 0,
            // 动态组件
            activeComponent: [
                [
                    "inverseDetail", //采集器
                    "monitoringDetail", //监控器
                    "collectDetail", //逆变器
                    "optimizeDetail", //优化器
                    "ammeterDetail", //电表
                ],
                "alarmMsg",
                "joinMsg",
                "historyData",
            ],
            // 监控器的tab页
            monitoringComponent: [
                "monitoringDetail", //详情
                "powerThermal", //发电量热力图
                "temperature",
            ],
            detailList: {},
            // 设备请求详情接口
            requestApi: [queryInverterDetails, queryMonitorDetails],
            // 编辑抽屉
            topDrawer: false,
            stationName: "", // 子系统电站名称
            // 气象站
            weatherData: [
                {value: "", prop: "ambientTemperature", title: "环境温度", unit: "℃"},
                {value: "", prop: "temperature", title: "温度1", unit: "℃"},
                {value: "", prop: "pointTemperature", title: "露点温度", unit: "℃"},
                {value: "", prop: "ambientHumidity", title: "环境湿度", unit: "℃"},
                {
                    value: "",
                    prop: "totalRadiationValue",
                    title: "总辐射1瞬时值",
                    unit: "W/㎡",
                },
                {
                    value: "",
                    prop: "scatteredRadiationValue",
                    title: "散辐射瞬时值",
                    unit: "W/㎡",
                },
                {
                    value: "",
                    prop: "directRadiationValue",
                    title: "直辐射瞬时值",
                    unit: "W/㎡",
                },
                {
                    value: "",
                    prop: "netRadiationValue",
                    title: "净辐射瞬时值",
                    unit: "W/㎡",
                },
                {
                    value: "",
                    prop: "photosyntheticRadiationValue",
                    title: "光合辐射瞬时值",
                    unit: "W/㎡",
                },
                {
                    value: "",
                    prop: "ultravioletRadiationValue",
                    title: "紫外辐射瞬时值",
                    unit: "W/㎡",
                },
                {value: "", prop: "windDirection", title: "风向瞬时值", unit: "°"},
                {value: "", prop: "windSpeed", title: "风速瞬时值", unit: "m/s"},
                {
                    value: "",
                    prop: "windSpeedTwoMinute",
                    title: "2分钟风速",
                    unit: "m/s",
                },
                {
                    value: "",
                    prop: "windSpeedTenMinute",
                    title: "10分钟风速",
                    unit: "m/s",
                },
                {
                    value: "",
                    prop: "totalRadiationInterval",
                    title: "总辐射1间隔累计",
                    unit: "MJ/㎡",
                },
                {
                    value: "",
                    prop: "dayRainfallTotal",
                    title: "雨量日累计",
                    unit: "mm",
                },
                {value: "", prop: "sunshineHour", title: "日照时(min)", unit: "min"},
                {
                    value: "",
                    prop: "dayTotalRadiation",
                    title: "总辐射1日累计",
                    unit: "MJ/㎡",
                },
                {value: "", prop: "collectorPower", title: "采集器电量", unit: "v"},
                {value: "", prop: "createTime", title: "更新时间", unit: ""},
            ],
            yHeight:
                document.documentElement.clientHeight -
                document.documentElement.clientHeight / 3.7,
        };
    },
    components: {
        InverterDetail,
        collectDetail,
        monitoringDetail,
        inverseDetail,
        optimizeDetail,
        alarmMsg,
        historyData,
        ammeterDetail,
        survey,
        powerThermal,
        temperature,
        joinMsg,
        editDrawer,
    },
    computed: {
        getColumns() {
            return allColumns[this.activeTab];
        },
        getXWidth() {
            return this.columns.length * 140;
        },
        // 获取状态
        getStatus() {
            let statusMsg = statusCode.find((s) => {
                return s.status == this.code;
            });
            if (statusMsg) {
                return statusMsg.label;
            } else {
                return "---";
            }
        },
        // 计算组件
        getComponent() {
            if (this.activeTab == 1) {
                return "joinMsg";
            }
            if (this.activeTab == 2 && this.drawerActive != 0) {
                return this.monitoringComponent[this.drawerActive];
            }
            if (this.drawerActive == 0) {
                return this.activeComponent[0][this.activeTab - 1];
            }
            return this.activeComponent[this.drawerActive];
        },
    },
    methods: {
        getMonitoringStatus(status) {
            let statusValue = this.monitoringStatus.find((item) => {
                return item.value == status;
            });
            if (statusValue) {
                return statusValue.label;
            } else {
                return "未知状态";
            }
        },
        getInverStatus(status) {
            let statusValue = this.inverseStatus.find((item) => {
                return item.value == status;
            });
            if (statusValue) {
                return statusValue;
            } else {
                return "未知状态";
            }
        },
        changeicon(record) {
            record.status = !record.status;
        },
        onSelectChange(selectedRowKeys) {
            console.log("selectedRowKeys changed: ", selectedRowKeys);
            this.selectedRowKeys = selectedRowKeys;
        },
        // 搜索框功能
        onSearch(value) {
            console.log(value);
        },
        tableChange(rowkeys, rows) {
            this.selectedRowKeys = rowkeys;
        },
        chooseId(id) {
            this.activeId = id;
            this.getTableList();
        },
        treeHeadChoose(id) {
            if (this.activeId != id) {
                this.activeId = id;
                this.getTableList();
            }
            this.treeHead = !this.treeHead;
        },
        callback(key) {
            this.drawerActive = 0;
            this.columns = allColumns[this.activeTab];
            if (this.activeTab == 1) {
                this.drawerTabList = [
                    {
                        value: "0",
                        label: "连接信息",
                    },
                ];
            } else if (this.activeTab == 2) {
                this.drawerTabList = this.monitoringTab;
            } else if (this.activeTab == 6) {
                this.getWeatherStation();
                return;
            } else {
                this.drawerTabList = this.normalTabList;
            }
            this.getTableList();
        },
        getTableList(pageMsg) {
            let requestPage = {
                page: 1,
                limit: 50,
            };
            if (pageMsg?.page) {
                requestPage.page = pageMsg.page;
                requestPage.limit = pageMsg.pageSize;
            }
            let device = {deviceType: this.activeTab, ...requestPage};
            queryDeviceList({
                stationId: this.activeId,
                ...device,
            }).then((res) => {
                this.tableData = res.data;
                this.total = res.count;
            });
        },
        reload() {
            if (this.activeTab == 6) {
                this.getWeatherStation();
            } else {
                this.getTableList();
            }
        },
        // 打开抽屉详情
        toDetail(data) {
            if (this.activeTab == 2) {
                this.getTabName = `${data.deviceTitle ?? "--"}：${data.deviceSn}`;
                this.currentData = data;
                this.detailDrawer = true;
            }
        },
        closeDetailDrawer() {
            this.detailDrawer = false;
        },
        // 更改抽屉里的tab
        changeDrawerTab(value) {
            this.drawerActive = value;
        },
        // 获取详情信息
        getDetail() {
            let request = this.requestApi[this.activeTab - 1];
            console.log(request);
            if (request) {
                // queryInverterDetails
                request({id: this.currentData.id})
                    .then((res) => {
                        this.detailList = res.data;
                        this.code = res.data?.inverterSystem?.workStatus;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },

        // 编辑操作
        editOpen(data) {
            if (this.activeTab == 3) {
                this.currentData = data;
                this.topDrawer = true;
                console.log(data);
            }
        },
        // 关闭编辑框
        closeEditDrawer() {
            this.topDrawer = false;
        },
        reloadTable() {
            this.topDrawer = false;
            this.getTableList();
        },
        // 气象站详情
        getWeatherStation() {
            weatherStation({id: this.activeId}).then((res) => {
                console.log(res);
                this.weatherData.forEach((item) => {
                    item.value = res.data[item.prop];
                });
            });
        },
        exportData() {
            downloadDevice({
                deviceType: this.activeTab,
                stationId: this.activeId,
            }).then((res) => {
                exportDown(res, "设备列表");
            });
        },
        watchResize() {
            window.addEventListener("resize", this.screenResize);
        },
        screenResize: debound(function () {
            // document.documentElement.clientHeight;
            this.yHeight =
                document.documentElement.clientHeight -
                document.documentElement.clientHeight / 3.7;
        }, 200),
    },
    mounted() {
        this.stationName = sessionStorage.getItem("stationName");
        this.columns = allColumns[this.activeTab];
        this.getTableList();
        this.watchResize();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.screenResizec);
    },
};
</script>

<style lang="less" scoped>
.section-header {
    padding: 20px;
    background-color: #ffffff;
}

.flex-main {
    .head {
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: white;

        .title {
            margin-top: 10px;
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        background-color: white;

        .main-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            border-bottom: 1px solid #dddddd;

            .right {
                display: flex;
                align-content: center;

                .prompt {
                    color: #ffb450;
                }

                .prompt:hover {
                    color: #1890ff;
                }
            }
        }

        .main-content {
            flex: 1;
            display: flex;
            width: 100%;
            height: 0;

            .content-left {
                width: 250px;
                border-right: 2px solid #c4c4c4;

                .tree-list {
                    width: 100%;
                    font-size: 12px;
                    margin-top: 32px;

                    .head-station {
                        display: flex;
                        color: #048fff;
                        align-items: center;
                        flex-wrap: wrap;
                        padding: 10px;
                        box-sizing: border-box;
                        cursor: pointer;
                        font-size: 16px;

                        .head-name {
                            margin-left: 6px;
                        }

                        i {
                            transition: all 0.25s;
                        }

                        .rote-icon {
                            transform: rotate(90deg);
                        }
                    }

                    .head-color {
                        color: #515561;
                    }

                    .col-list {
                        color: #515561;
                        font-size: 12px;
                        transition: all 0.5s;
                        overflow: hidden;

                        .one-col {
                            margin: 6px 0;
                            padding-left: 64px;
                            box-sizing: border-box;
                            cursor: pointer;
                        }

                        .choose-col {
                            color: #048fff;
                        }
                    }

                    .show-col {
                        max-height: 100%;
                        overflow-y: auto;
                    }

                    .hide-col {
                        height: 0;
                    }
                }
            }

            .content-right {
                width: 0;
                flex: 1;
                display: flex;
                flex-direction: column;

                .right-table {
                    height: 0;
                    flex: 1;
                }

                .table-footer {
                    display: flex;
                    justify-content: space-between;
                    padding: 12px 16px;
                    width: 100%;

                    .operation-btn {
                        /deep/ .ant-btn {
                            font-size: 12px;
                            margin-right: 8px;
                        }
                    }
                }

                .list-box {
                    flex-grow: 1;

                    .weather-box {
                        height: 100%;
                        overflow-y: auto;
                        display: grid;
                        padding: 16px;
                        box-sizing: border-box;
                        grid-template-columns: repeat(3, 1fr); /* 三列等宽 */
                        grid-auto-rows: 60px; /* 自动调整行高度 */
                        grid-gap: 10px; /* 列间距和行间距，可根据需要调整 */

                        .one-msg {
                            font-size: 12px;
                            color: #00000073;

                            .one-detail {
                                color: #515561;
                            }
                        }
                    }
                }
            }
        }
    }
}

.add-power {
    transform: translate(0) !important;

    .head-box {
        .name-box {
            display: flex;
            justify-content: space-between;

            span {
                font-size: 18px;
                color: #252b3a;
            }

            .operation-right {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.45);

                .mr_16 {
                    margin-right: 16px;
                    transition: all 0.5s;
                    cursor: pointer;

                    &:hover {
                        color: #048fff;
                    }
                }

                .close-box {
                    background: #ff7875;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 28px;
                    height: 28px;
                    font-size: 16px;
                    color: #fff;
                    border-radius: 50%;

                    &:hover {
                        i {
                            transform: rotate(90deg);
                        }
                    }

                    i {
                        transition: all 0.3s;
                    }
                }
            }
        }

        .status-box {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .left-box {
                display: flex;
                align-items: center;

                i {
                    font-size: 12px;
                }

                span {
                    font-size: 14px;
                    color: #515561;
                    margin-left: 6px;
                }
            }

            .right-time {
                font-size: 12px;
                color: #515561;
            }
        }
    }

    .drawer-tab-box {
        padding: 0 8px;
        margin-top: 16px;

        .all-tabs {
            display: flex;

            span {
                padding: 12px 0 8px;
                margin-right: 24px;
                font-size: 12px;
                color: #00000073;
                cursor: pointer;
            }

            .active-drawer-tab {
                color: #048fff;
                border-bottom: 2px solid #048fff;
                font-weight: 700;
            }
        }
    }

    .content-box {
        padding: 0 16px;
        box-sizing: border-box;
    }
}
</style>
